// @ts-check
/* eslint-disable prefer-arrow-callback */

require('../../../../../../app_toysrus/cartridge/client/default/js/components/forms/Spinbutton')();
var scrollAnimate = require('../../../../../../app_toysrus/cartridge/client/default/js/components/scrollAnimate');
var cookieUtil = require('../../../../../../app_toysrus/cartridge/client/default/js/utils/cookieUtil');
var csrfHelper = require('../../../../../../app_toysrus/cartridge/client/default/js/csrf');

/**
 * Updates passed input field(s) mandatory flag and visibility
 * @param {JQuery<HTMLElement>} $field - jQuery-object containing fields to be processed
 * @param {boolean} [mandatory] - *true* if should be set mandatory, *false/unset* - optional
 * @param {boolean} [hidden] - *true* if should be hidden, *false* - show, *unset* - don't touch
 * @private
 */
function updateFieldAttrs($field, mandatory, hidden) {
    if (mandatory === true) {
        $field
            .prop('required', true)
            .addClass('required')
            .closest('.form-group')
            .addClass('required');
    } else {
        $field
            .removeAttr('required')
            .removeClass('required')
            .closest('.form-group')
            .removeClass('required');
    }

    if (hidden === true) {
        $field
            .closest('.form-group')
            .addClass('h-hidden');
    } else if (hidden === false) {
        $field
            .closest('.form-group')
            .removeClass('h-hidden');
    }
}

$('.js-co-registrant-name').on('change', function () {
    let emptyInputs = true;
    let coFields = $('.registrant-form')
        .find('.js-co-registrant-name')
        .toArray();

    coFields.forEach((field) => {
        if ($(field).val().toString().trim()) {
            emptyInputs = false;
        }
    });

    if (emptyInputs) {
        coFields.forEach((field) => {
            $(field)
                .parent('.form-group')
                .removeClass('required')
                .addClass('m-optional');
            $(field)
                .removeAttr('required')
                .removeClass('is-invalid')
                .siblings('.invalid-feedback')
                .hide();
        });
    } else {
        coFields.forEach((field) => {
            $(field)
                .attr('required', 'true')
                .parent('.form-group')
                .removeClass('m-optional')
                .addClass('required');
        });
    }
});

$('.js-make-default-registry').on('click', function () {
    const url = $(this).data('makeDefaultUrl');
    $.spinner().start();
    $.get(url).then(() => {
        location.reload();
    }).catch(() => {
        $.spinner().stop();
    });
});

$('.js-gr-landing-login').each(function (_, form) {
    const $form = $(form);

    const $numberField = /** @type {JQuery<HTMLInputElement>} */ ($form.find('.js-registry-number-field'));
    const $emailField = /** @type {JQuery<HTMLInputElement>} */ ($form.find('.js-registry-email-field'));
    const $passwordFieldError = $form.find('.js-registry-password-error');
    const $passwordField = $form.find('.js-registry-password-field');
    const $submitButton = $form.find('.js-submit-button');

    $form.on('submit', () => {
        $submitButton.prop('disabled', true);

        $form.spinner().start();
        $.post({
            url: $form.attr('action'),
            data: $form.serialize(),
            type: $form.attr('method')
        }).then(response => {
            $submitButton.prop('disabled', false);
            $passwordField.val('');

            if (response && response.error && response.error.length) {
                $passwordFieldError.text($passwordFieldError.data('password-error')).show();
                $passwordField.addClass('is-invalid');

            } else if (response.redirectUrl) {
                window.location.assign(response.redirectUrl);
            } else if (response.csrfError) {
                window.location.reload();
            }
        }).always(() => {
            $form.spinner().stop();
        });

        return false;
    });

    $numberField.on('change', () => {
        updateFieldAttrs($emailField, !$numberField.get(0).validity.valid);
    });

    $emailField.on('change', () => {
        updateFieldAttrs($numberField, !$emailField.get(0).validity.valid);
    });
});

$('.js-gr-landing-search').each(function (_, form) {
    const $form = $(form);

    const $searchByField = $form.find('#searchBy');
    const $lastNameField = $form.find('#searchLastName');
    const $altNameField = $form.find('#searchAltName');
    const $coRegFirstNameField = $form.find('#coRegistrantFirstName');
    const $coRegLastNameField = $form.find('#coRegistrantLastName');
    const $registryNoField = $form.find('#searchRegistryNo');
    const $otherFields = $form.find('#searchFirstName, #searchGRCity, #searchGRState');

    $searchByField.on('change', () => {
        switch ($searchByField.val()) {
            case 'registrant':
                updateFieldAttrs($lastNameField, true, false);
                updateFieldAttrs($altNameField, false, true);
                updateFieldAttrs($registryNoField, false, true);
                updateFieldAttrs($otherFields, false, false);
                updateFieldAttrs($coRegLastNameField, false, true);
                updateFieldAttrs($coRegFirstNameField, false, true);
                break;

            case 'coregistrant':
                updateFieldAttrs($lastNameField, false, true);
                updateFieldAttrs($altNameField, false, true);
                updateFieldAttrs($registryNoField, false, true);
                updateFieldAttrs($otherFields, false, true);
                updateFieldAttrs($coRegLastNameField, true, false);
                updateFieldAttrs($coRegFirstNameField, true, false);
                break;

            case 'altname':
                updateFieldAttrs($lastNameField, false, true);
                updateFieldAttrs($altNameField, true, false);
                updateFieldAttrs($registryNoField, false, true);
                updateFieldAttrs($otherFields, false, false);
                updateFieldAttrs($coRegLastNameField, false, true);
                updateFieldAttrs($coRegFirstNameField, false, true);
                break;

            case 'registryno':
                updateFieldAttrs($lastNameField, false, true);
                updateFieldAttrs($altNameField, false, true);
                updateFieldAttrs($registryNoField, true, false);
                updateFieldAttrs($otherFields, false, true);
                updateFieldAttrs($coRegLastNameField, false, true);
                updateFieldAttrs($coRegFirstNameField, false, true);
                break;
        }
    }).trigger('change');
});

let $addingButton;
$('.js-add-to-cart-registry').on('click', function (e) {
    const $button = $(this);
    $button.parent().find('.js-stock-error').remove();

    if (!e.isTrigger && $button.data('esrb') && !cookieUtil.get('esrb')) {
        window.dialogManager.openDialog('modal', '#ageModal', $button.get(0));
        $addingButton = $button;
    } else {
        $.spinner().start();

        const $registryTile = $button.closest('.js-registry-product-info');
        const desiredQtyFromInput = Number($registryTile.find('.js-line-item-qty').val()) || 1;
        const purchased = Number($registryTile.find('.js-line-item-purchased').val()) ||
            $registryTile.find('.js-purchased-quantity').data('purchasedQuantity') ||
            0;
        const desiredQty = Number($registryTile.find('.js-desiredQuantity-publicView').val()) || desiredQtyFromInput;

        $.ajax({
            url: $button.data('url'),
            method: 'POST',
            data: {
                pid: $button.attr('data-pid'),
                registryNumber: $button.data('registryNumber'),
                registryOwner: $button.data('registryOwner'),
                quantity: Math.max(desiredQtyFromInput - purchased, 1),
                desiredQty: desiredQty,
                purchased: purchased
            }
        }).done(data => {
            if (data.error && data.message && data.message.quantityMessage) {
                $button.after(
                    `<div class="b-gift_tile-stock_error js-stock-error stock-error text-center" role="alert">${data.message.quantityMessage}</div>`
                );

                $('body').trigger('minicart:update:qty', {qty: data.quantityTotal});
            }

            $('body').trigger('product:afterAddToCart', data);
        }).always((data) => {
            $.spinner().stop();
            csrfHelper.checkCsrfTokenInResponse(data);
        });
    }
});

$(document).on('click', '.js-age-esrb-confirm', () => {
    window.dialogManager.closeDialog();
    cookieUtil.set('esrb', true);

    if ($addingButton) {
        $addingButton.trigger('click');
    }
});

$('.js-giftRegistry-registrantMessage').on('change', function () {
    const $this = $(this);
    const $otherMessage = $('.js-giftRegistry-otherMessage');

    $otherMessage.toggleClass('h-hidden', $this.val() !== '99');
}).trigger('change');

$('.js-remove-registry-product').on('click', function () {
    const $button = $(this);
    const tokenData = {};

    tokenData[csrfHelper.getCsrfToken().tokenName] = csrfHelper.getCsrfToken().token;
    csrfHelper.getCsrfToken();

    $.spinner().start();
    $.ajax({
        url: $button.data('url'),
        method: 'GET',
        data: tokenData
    }).then(response => {
        if (response && response.success) {
            location.reload();
        } else {
            $.spinner().stop();
        }
        $.spinner().stop();
    }).catch((error) => {
        csrfHelper.checkCsrfTokenInResponse(error);
        $.spinner().stop();
    });
});


$('.js-print-registry').on('click', function () {
    window.print();

    return false;
});

$('.js-registry-update').on('change spinbutton:change', function () {
    const $productInfo = $(this).closest('.js-registry-product-info');
    const purchased = Number($productInfo.find('.js-line-item-purchased').val());

    $.spinner().start();
    $.ajax({
        url: $productInfo.data('editUrl'),
        method: 'POST',
        data: {
            desired: $productInfo.find('.js-line-item-desired').val(),
            purchased
        },
        success: function(data) {
            var purchasedQuantity = data.purchasedQuantity;
            $('.js-br-total-quantity').text(data.totalQuantity);
            $('.js-br-purchased-quantity').text(purchasedQuantity);
            $('.js-br-remaining-quantity').text(data.remainingQuantity);
        }
    }).always(() => {
        $productInfo.find('.js-remove-registry-product').toggleClass('h-hidden', purchased > 0);
        $.spinner().stop();
    });
});

$('.js-continue-create-registry').on('click', function () {
    var form = $('.js-form-registry').get(0);


    if (!form) {
        return false;
    } else if (!form.checkValidity()) {
        if (form) {
            for (let i = 0; i < form.length; i++) {
                if (!form[i].validity.valid) {
                    scrollAnimate(form[i]);
                    break;
                }
            }
        }

        return false;
    }
    $('.js_legal-consent').prop('required', true);

    const fieldsMap = {
        '.registrant-first-name-summary': 'Event_registrant_firstName',
        '.registrant-last-name-summary': 'Event_registrant_lastName',
        '.registrant-co-first-name-summary': 'Event_registrant_coRegFirstName',
        '.registrant-co-last-name-summary': 'Event_registrant_coRegLastName',
        '.registrant-alt-name-summary': 'Event_registrant_altName',
        '.registrant-email-summary': 'Event_registrant_email',
        '.event-address1-summary': 'ShippingAddress_postEventShippingAddress_address1',
        '.event-address2-summary': 'ShippingAddress_postEventShippingAddress_address2',
        '.event-city-summary': 'ShippingAddress_postEventShippingAddress_city',
        '.event-state-summary': 'ShippingAddress_postEventShippingAddress_states_stateCode option:selected',
        '.event-postal-summary': 'ShippingAddress_postEventShippingAddress_postalCode',
        '.event-phone-summary': 'ShippingAddress_postEventShippingAddress_phone'
    };
    Object.keys(fieldsMap).forEach(key => {
        $(key).html($('#dwfrm_giftRegistry_giftRegistry' + fieldsMap[key]).val());
    });

    $('.event-postal-summary').html(($('.event-postal-summary').html() || '').replace(/ /ig, ''));
    const date = new Date();

    const locale = $('html').attr('lang');
    date.setFullYear($('.js-joined-date.js-year').val());
    date.setMonth($('.js-joined-date.js-month').val() - 1);
    date.setDate($('.js-joined-date.js-day').val());

    $('.event-date-summary').html(date.toLocaleDateString(locale, {
        dateStyle: 'medium'
    }));

    const $firstBaby = $('#dwfrm_giftRegistry_giftRegistryEvent_event_firstBaby');
    let firstBaby = $firstBaby.val() + '';

    if (firstBaby) {
        firstBaby = $firstBaby.find('option:selected').text();
    }
    $('.event-firstbaby-summary').html(firstBaby);

    const $message = $('#dwfrm_giftRegistry_giftRegistryAdditionalInfo_registrantMessage');
    let message = $message.val() + '';

    if (message) {
        let $selectedMessage = $message.find('option:selected');
        message = $selectedMessage.val() === '99' ? $('.js-giftRegistry-otherMessage').val() : $selectedMessage.text();
    }
    $('.event-registrant-message-summary').html(message);

    const $lang = $('#dwfrm_giftRegistry_giftRegistryAdditionalInfo_language');
    let lang = $lang.val() + '';
    if (lang) {
        lang = $lang.find('option:selected').text();
    }
    $('.event-language-summary').html(lang);

    $('.event-and-registrant').addClass('h-hidden');
    $('.event-and-registrant-summary, .preview').removeClass('h-hidden');
    scrollAnimate($('.page-title'));
});

$('.js-edit-button').on('click', function (e) {
    e.preventDefault();
    $('.event-and-registrant').removeClass('h-hidden');
    $('.event-and-registrant-summary, .preview').addClass('h-hidden');
    scrollAnimate($($(this).data('section')));
    $('.js_legal-consent').prop('required', false);
    $('.js-giftregistry-password-field').val('');
});

$('.js-form-registry').submit(function (e) {
    var $form = $(this);
    var url = $form.attr('action');

    e.preventDefault();
    $.spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $.spinner().stop();
            if (data.success && data.result) {
                window.location.assign(data.result.url);
            } else {
                $('.failedAttempt')
                    .removeClass('h-hidden')
                    .html(data.errorMsg);
            }
        },
        error: function (err) {
            $.spinner().stop();
            $('.failedAttempt')
                .removeClass('h-hidden')
                .html(err.responseJSON.errorMsg);
        }
    });

    return false;
});

$('.js-form-registry').find('.postalCode').on('change', function (e) {
    e.preventDefault();
    var $input = $(this);

    var restrictedFsaForShipping = $input.data('invalid-fsa-list').split(',');

    if (restrictedFsaForShipping) {
        var FSA = $input.val().trim().substring(0, 3).toUpperCase();
        var isValid = restrictedFsaForShipping.indexOf(FSA) === -1;

        if (!isValid) {
            $('div#postal-code-warning').show();
        } else {
            $('div#postal-code-warning').hide();
        }
    }
});

const $legalConsent = $('.js_legal-consent');
const $registryAction = $('.js-create-registry-action');

$registryAction.on('click', function () {
    setTimeout(() => {
        $registryAction.prop('disabled', !$legalConsent.prop('checked'));
    }, 1);
});
$legalConsent.on('change', function () {
    $registryAction.prop('disabled', !$legalConsent.prop('checked'));
});

$('.js-joined-date').on('change', function () {
    var $select = $('.js-joined-date'),
        $month = $select.filter('.js-month'),
        $day = $select.filter('.js-day'),
        $year = $select.filter('.js-year'),
        month = parseInt($month.val()),
        day = parseInt($day.val()),
        year = parseInt($year.val()),
        currentDate = (new Date()),
        //   r = currentDate.getMonth() >= 1 ? (((year + 1) % 4) == 0) : ((year % 4) == 0),
        maxDaysFuture = $month.data('maxDaysFuture') || 365;


    const dayOptionTitleHtml = $day.find('option:first').html();
    const yearOptionTitleHtml = $year.find('option:first').html();

    if (!month) {
        return;
    }
    currentDate.setDate(1);
    currentDate.setMonth(month);
    currentDate.setDate(0);

    const dayOfMonth = currentDate.getDate();
    $day.children().remove();

    if (dayOptionTitleHtml) {
        $day.append($('<option>').val('').html(dayOptionTitleHtml).prop('selected', false));
    }
    for (var i = 1; i <= dayOfMonth; i++) {
        $day.append($('<option>').val(i).html(String(i)).prop('selected', day === i));
    }
    if (!day) {
        return;
    }

    currentDate.setDate(1);
    currentDate.setMonth(month - 1);
    currentDate.setDate(day);
    if (currentDate.getTime() < (new Date()).getTime()) {
        currentDate.setFullYear(currentDate.getFullYear() + 1);
    }
    var minYear = currentDate.getFullYear();
    currentDate.setDate(currentDate.getDate() + maxDaysFuture);
    $year.children().remove();
    new Option(yearOptionTitleHtml, '', false);
    if (yearOptionTitleHtml) {
        $year.append($('<option>').val('').html(yearOptionTitleHtml).attr('selected', false));
    }
    $year.append($('<option>')
        .val(minYear)
        .html(String(minYear))
        .attr('selected', year === minYear));
}).filter('.js-day').trigger('change');


$('.js-gr-forgot-password').submit(function () {
    var $form = $(this);
    var url = $form.attr('action');

    $.spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $.spinner().stop();

            if (data.success) {
                window.dialogManager.openDialog('modal', '#forgotPasswordResetModal', $('.js-gift-forgot-password')[0]);
                $('.failedAttempt').addClass('h-hidden');
            } else {
                $('.failedAttempt')
                    .removeClass('h-hidden')
                    .html(data.errorMsg);

                $form.find('.js-registry-email-field').addClass('is-invalid');
            }
        },
        error: function (err) {
            $.spinner().stop();

            $('.failedAttempt')
                .removeClass('h-hidden')
                .html(err.responseJSON.errorMsg);
        }
    });

    return false;
});

function removeIsClickAttribute() {
    let currentUrl = window.location.href;
    const attributePosiotion = currentUrl.indexOf('isClick');
    if (attributePosiotion !== -1) {
        let partOfUrlBeforeParameter = currentUrl.slice(0, attributePosiotion);
        const attributesArray = currentUrl.slice(attributePosiotion).split('&');
        attributesArray.splice(0);
        const attributesArrayWithOutIsClick = attributesArray;
        const partOfUrlAfterDeletingIsClick = attributesArrayWithOutIsClick.join('&');
        if (partOfUrlAfterDeletingIsClick === '') {
            partOfUrlBeforeParameter = partOfUrlBeforeParameter.slice(0, -1);
        }
        const newUrl = partOfUrlBeforeParameter + partOfUrlAfterDeletingIsClick;
        history.pushState({status: 'deletedIsClick'}, '', newUrl);
    }
}
removeIsClickAttribute();
