/* eslint-disable prefer-arrow-callback */
'use strict';

$(document).ready(() => {
    require('./giftRegistry/giftRegistry');
    //Bind Google Address Lookup
    // cartridges\plugin_giftregistry\cartridge\client\default\js\giftRegistry.js
    const googleAddress = require('../../../../../app_toysrus/cartridge/client/default/js/components/googleaddress');
    googleAddress.init();

    $('.js-barcode').each(function () {
        const jsBarcode = require('jsbarcode');
        var $this = $(this);
        jsBarcode($this.get(0), '2610' + $this.attr('data-registry-number'), { format: 'EAN13' });
    });
    const BabyRegistryThankYouSelector = $('#b-baby_registry-thank-you-link');
    const homeURL = BabyRegistryThankYouSelector.attr('href');
    const url = window.location.href;
    const urlObj = new URL(url);
    const registryNumber = urlObj.searchParams.get('registryNumber');
    BabyRegistryThankYouSelector.attr('href', homeURL + '?registryNumber=' + registryNumber);
});
